import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { urlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import useSite from '../hooks/use-site'

const makeHeadTags = site => {
  const image = urlFor(buildImageObj(site.image)).width(1200).height(630).url()
  return [
    {
      type: 'script',
      async: true,
      src: 'https://storage.googleapis.com/embed-script.letsway.com/v1-latest/main.js',
    },
    { type: 'meta', name: 'description', content: site.description },
    { type: 'meta', name: 'image', content: image },
    { type: 'meta', property: 'og:url', content: '/experiences/'},
    { type: 'meta', property: 'og:title', content: site.title },
    { type: 'meta', property: 'og:description', content: site.description },
    { type: 'meta', property: 'og:image', content: image },
  ]
}

const Experiences = ({ uri }) => {
  const site = useSite()
  const fixesForHelmetOverwrite = makeHeadTags(site)

  useEffect(() => {
    fixesForHelmetOverwrite.forEach(tag => {
      const element = document.createElement(tag.type)
      Object.keys(tag).forEach(key => {
        if (key === 'type') return
        element.setAttribute(key, tag[key])
      })
      document.head.appendChild(element)
    })
  }, [])

  return (
    <Layout uri={uri}>
      <div id="kouto-embed-root" data-brand-id="145782af-78c9-4632-bd61-7f85e4c9b4c6"></div>
    </Layout>
  )
}

export default Experiences
